$('.filter input').change(function () {
    let $input = $(this),
        $filter = $input.closest('.filter'),
        $target = $($filter.data('target')) || '.js-filter-target',
        itemSelector = $filter.data('item') || '.js-filter-item',
        logic = $filter.data('logic'),
        logicOperator = '.',
        mixedCheckboxClass = 'filter--input--mixed',
        filterClass = 'js-filter--',
        neededClasses = [],
        $subFilter;

    if (logic && logic === 'OR') {
        logicOperator = ',.';
    }

    // move all sub-filter to the same value
    $subFilter = $input.closest('li').find('ul');

    if ($subFilter.length) {
        $input.closest('li').removeClass(mixedCheckboxClass);
        $input.closest('li').find('li').removeClass(mixedCheckboxClass);
        $subFilter.find('input').prop('checked', $input.is(':checked'));
    }

    // check if parent-filter needs the mixed modifier
    function parentFilterCheck($parentFilter) {
        let $parentFilterCheckbox;

        if ($parentFilter.length) {
            $parentFilterCheckbox = $parentFilter.find('> a label input');

            // restore default values (nothing checked)
            $parentFilter.removeClass(mixedCheckboxClass);
            $parentFilterCheckbox.prop('checked', false);

            if ($parentFilter.find('li input:checked').length === $parentFilter.find('li input').length) {
                // if everything is checked
                $parentFilterCheckbox.prop('checked', true);
            } else if ($parentFilter.find('li input:checked').length !== 0) {
                // = mixed
                $parentFilter.addClass(mixedCheckboxClass);
            }

            // move up one parent
            parentFilterCheck($parentFilter.closest('ul').closest('li'));
        }
    }

    parentFilterCheck($input.closest('ul').closest('li'));

    // get needed filter classes
    $filter.find('input:checked').each(function (i, el) {
        neededClasses.push(filterClass + $(el).val());
    });

    // show/hide items
    if (neededClasses.length) {
        $target.find(itemSelector).not('.filter').hide();
        $('.' + neededClasses.join(logicOperator)).closest(itemSelector).show();
    } else {
        $target.find(itemSelector).not('.filter').show();
    }

    // when the filter layout changes
    $filter.on('down.zf.accordionMenu up.zf.accordionMenu', function () {
        $target.trigger('filter.changed');
    });

    // items were filtered
    $target.trigger('filter.filtered');
});
