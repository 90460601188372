import '@babel/polyfill';

// ////////////////////////////////////////////////////////////
// bugsnag
import bugsnag from 'bugsnag-js';
if (CONFIG.bugsnag) {
    window.bugsnagClient = bugsnag({
        apiKey: CONFIG.bugsnag,
        collectUserIp: false,
        releaseStage: CONFIG.env,
        notifyReleaseStages: ['prod', 'stage'],
    });
}

// ////////////////////////////////////////////////////////////
// optimized font loading
import fontFaceLoader from '@spinnwerk/font-face-loader';
fontFaceLoader({
    fonts: ['Burlington'],
});

// ////////////////////////////////////////////////////////////
// variables
const body = document.body,
    $menuButton = $('.menu-toggle');

let ignoreOnBeforeUnload = false,
    offCanvasActive = 0;

// ////////////////////////////////////////////////////////////
// import version from './lib/version';
import './_includes/foundation';

// ////////////////////////////////////////////////////////////
// init foundation

// show focus style only after a tab keystroke
function handleFirstTab(event) {
    if (event.keyCode === 9) {
        body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', handleFirstTab);
    }
}

window.addEventListener('keydown', handleFirstTab);

// fade out on page leave
$('a[href^="mailto"], a[href^="tel"], a[download], .download, a[href^="javascript:"]').on('click', () => {
    ignoreOnBeforeUnload = true;
});

window.addEventListener('beforeunload', () => {
    if (!ignoreOnBeforeUnload) {
        body.classList.add('body--fade-out');
    }

    ignoreOnBeforeUnload = false;
});

$(document)
    .foundation()
    .on('opened.zf.offcanvas', (event) => {
        let $target = jQuery(event.target);

        offCanvasActive++;

        body.classList.add('is-off-canvas-open');
        body.classList.add('is-off-canvas-' + $target.data('transition') + '-' + $target.data('position'));

        // only for our main menu off-canvas
        if (event.target.id === 'off-canvas') {
            $menuButton.addClass('is-active');
        } else {
            $menuButton.addClass('is-disabled');
        }
    })
    .on('closed.zf.offcanvas', (event) => {
        let $target = jQuery(event.target);

        offCanvasActive--;

        if (offCanvasActive === 0) {
            body.classList.remove('is-off-canvas-open');
        }

        body.classList.remove('is-off-canvas-' + $target.data('transition') + '-' + $target.data('position'));

        // only for our main menu off-canvas
        if (event.target.id === 'off-canvas') {
            $menuButton.removeClass('is-active');
        } else {
            $menuButton.removeClass('is-disabled');
        }
    });

// anchor scrolling
if (CONFIG.scrollFromHashChanges !== true) {
    new Foundation.SmoothScroll($('a[href*="#"]:not([href="#"]):not(.no-scroll)'));
} else {
    $('a[href*="#"]:not([href="#"]):not(.no-scroll)').on('click', event => {
        event.preventDefault(); // do not allow setting the hash, as some browser move directly to the target, before the onhashchange event is triggered

        let $target = $(event.target).closest('[href]'),
            href = $target.attr('href');

        if (location.pathname != href.split('#')[0]) {
            if (href && href[0] == '/') {
                location.href = href;
                return;
            }
        }

        // get the actual hash of the href
        if (href && href.indexOf('#') !== -1) {
            href = href.split('#').pop();
        }

        // mask the hash so that browsers don't navigate
        if (href && href[0] !== '/') {
            href = '/' + href;
        }

        location.hash = href; // set hash explicitly to trigger the onhashchange event, on which scrolling depends
    });
}

// ////////////////////////////////////////////////////////////
// sliding header
require(['./lib/headroom'], ({ default: Headroom }) => {
    let hr = new Headroom;

    setTimeout(function () {
        hr.show();
    }, 4000);
});

// ////////////////////////////////////////////////////////////
// form helpers
import './lib/form/form';

// ////////////////////////////////////////////////////////////
// masonry
import './lib/layouts/masonry';

// ////////////////////////////////////////////////////////////
// swiper
import './lib/layouts/swiper';

// ////////////////////////////////////////////////////////////
// map
import './lib/layouts/map';

// ////////////////////////////////////////////////////////////
// filter
import './lib/filter';

// ////////////////////////////////////////////////////////////
// update url based on scrolling
require(['./lib/section-nav'], ({ default: SectionNav }) => {
    new SectionNav({
        scrollFromHashChanges: CONFIG.scrollFromHashChanges || false,
        getHash(el) {
            return '/' + (el.getAttribute('data-section-nav-anchor') || el.id);
        },
        getActiveStateSelector(hash) {
            return `[href="#${hash.replace(/^#?\//, '')}"]`;
        },
        getAttribute(hash) {
            return hash.replace(/#\//, '');
        },
    });
});

// ////////////////////////////////////////////////////////////
// browser alerts (async)
require(['./lib/alerts/old-browser'], (oldBrowserAlert) => {
    oldBrowserAlert.default();
});

function headerLogoBlinkStart() {
    $('.header__logo-layer__background').css('opacity', '0');
}

function headerLogoBlinkEnd() {
    $('.header__logo-layer__background').css('opacity', '1');
}

function headerLogoBlink() {
    let blinkStart = ((Math.random() * 3)) * 1000,
        blinkEnd = blinkStart + 250;

    setTimeout(headerLogoBlinkStart, blinkStart);
    setTimeout(headerLogoBlinkEnd, blinkEnd);
}

$(function () {
    let animationDuration,
        animationDelay,
        blinkDuration = ((Math.random() * 3) + 1) * 1000;

    // flower fade in
    if (!$('.header__flower-layer__flower--1').hasClass('js-shown') && !$('.header__flower-layer__flower--2').hasClass('js-shown') && !$('.header__flower-layer__flower--3').hasClass('js-shown') && !$('.header__flower-layer__flower--4').hasClass('js-shown')) {
        $('.header__flower-layer__flower').each(function (index) {
            animationDuration = Math.random();
            animationDelay = Math.random() * 2;
            $('.header__flower-layer__flower--' + (index + 1))
                .attr('style', 'transition-duration:' + animationDuration + 's;transition-delay:' + animationDelay + 's;')
                .addClass('js-flower--shown');
        });
    }

    // logo should blink randomly
    setInterval(headerLogoBlink, blinkDuration);
});

//IE FIX position:fixed scrolling
/* eslint-disable */
if(navigator.userAgent.match(/Trident\/7\./)) {
    $('body').on("mousewheel", function () {
        event.preventDefault();

        var wheelDelta = event.wheelDelta;

        var currentScrollPosition = window.pageYOffset;
        window.scrollTo(0, currentScrollPosition - wheelDelta);
    });

    $('body').keydown(function (e) {
        var currentScrollPosition = window.pageYOffset;

        switch (e.which) {

            case 38: // up
                e.preventDefault(); // prevent the default action (scroll / move caret)
                window.scrollTo(0, currentScrollPosition - 120);
                break;

            case 40: // down
                e.preventDefault(); // prevent the default action (scroll / move caret)
                window.scrollTo(0, currentScrollPosition + 120);
                break;

            default: return; // exit this handler for other keys
        }
    });
}
/* eslint-enable */
