const $swiper = $('.swiper-container');

if ($swiper.length) {
    require(['swiper'], Swiper => {
        // loop over swiper instances and init them
        $swiper.each(function (i, container) {
            let $container = $(container),
                centeredSlides = $container.data('centered-slides'),
                height = $container.data('height') || undefined;

            new Swiper.default($container, { // eslint-disable-line new-cap
                loop: true,
                autoHeight: height === 'auto',
                height: !isNaN(height) ? height : undefined,
                centeredSlides: centeredSlides !== true && centeredSlides !== false ? true : centeredSlides,
                slidesPerView: 'auto',
                autoplay: $container.data('autoplay') || false,
                spaceBetween: $container.data('space-between') || 0,

                navigation: {
                    nextEl: $container.parent().find('.swiper-button-next'),
                    prevEl: $container.parent().find('.swiper-button-prev'),
                },

                pagination: {
                    el: $container.parent().find('.swiper-pagination'),
                    clickable: true,
                    type: 'bullets',
                },

                on: {
                    slideChangeTransitionEnd() {
                        // // force repaint after slide, some browser have render issues otherwise
                        // $container.each(function () {
                        //     this.style.display = 'none';
                        //     this.offsetHeight;
                        //     this.style.display = 'block';
                        // });
                    },
                },
            });
        });
    });
}
