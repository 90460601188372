import jQuery from 'jquery';

const $maps = $('.map');

require.ensure([], require => {
    jQuery($ => {
        const mapInit = function () {
            if ($maps.length) {
                require(['./../version', 'scriptjs/dist/script'], (version, $script) => {
                    // load google maps
                    $script('https://maps.googleapis.com/maps/api/js?key=AIzaSyDR4I3C3OqLWUa2OUtywL5MiaOqeCOXWD0', () => {
                        // loop over map container
                        $maps.each(function (i, container) {
                            let $container = $(container),
                                mapOptions = {
                                    zoom: 14,
                                    /* eslint-disable */
                                    styles: [{
                                        "featureType": "all",
                                        "elementType": "labels.text.fill",
                                        "stylers": [{"saturation": 36}, {"color": "#000000"}, {"lightness": 40}]
                                    }, {
                                        "featureType": "all",
                                        "elementType": "labels.text.stroke",
                                        "stylers": [{"visibility": "on"}, {"color": "#000000"}, {"lightness": 16}]
                                    }, {
                                        "featureType": "all",
                                        "elementType": "labels.icon",
                                        "stylers": [{"visibility": "off"}]
                                    }, {
                                        "featureType": "administrative",
                                        "elementType": "geometry.fill",
                                        "stylers": [{"color": "#000000"}, {"lightness": 20}]
                                    }, {
                                        "featureType": "administrative",
                                        "elementType": "geometry.stroke",
                                        "stylers": [{"color": "#000000"}, {"lightness": 17}, {"weight": 1.2}]
                                    }, {
                                        "featureType": "administrative.country",
                                        "elementType": "geometry",
                                        "stylers": [{"visibility": "off"}]
                                    }, {
                                        "featureType": "landscape",
                                        "elementType": "geometry",
                                        "stylers": [{"color": "#000000"}, {"lightness": 20}]
                                    }, {
                                        "featureType": "poi",
                                        "elementType": "geometry",
                                        "stylers": [{"color": "#000000"}, {"lightness": 21}]
                                    }, {
                                        "featureType": "road.highway",
                                        "elementType": "geometry.fill",
                                        "stylers": [{"color": "#000000"}, {"lightness": 17}]
                                    }, {
                                        "featureType": "road.highway",
                                        "elementType": "geometry.stroke",
                                        "stylers": [{"color": "#000000"}, {"lightness": 29}, {"weight": 0.2}]
                                    }, {
                                        "featureType": "road.arterial",
                                        "elementType": "geometry",
                                        "stylers": [{"color": "#000000"}, {"lightness": 18}]
                                    }, {
                                        "featureType": "road.local",
                                        "elementType": "geometry",
                                        "stylers": [{"color": "#000000"}, {"lightness": 16}]
                                    }, {
                                        "featureType": "transit",
                                        "elementType": "geometry",
                                        "stylers": [{"color": "#313030"}, {"lightness": 19}]
                                    }, {
                                        "featureType": "transit",
                                        "elementType": "labels.text",
                                        "stylers": [{"saturation": "-33"}]
                                    }, {
                                        "featureType": "water",
                                        "elementType": "geometry",
                                        "stylers": [{"color": "#2b3032"}, {"lightness": 17}]
                                    }]
                                    /* eslint-enable */
                                },
                                map,
                                markers = [],
                                bounds = new google.maps.LatLngBounds();

                            // add placemarks to the map
                            $container.find('.map__list__item div').each(function (i, item) {
                                let $item = $(item),
                                    pinFile = '/assets/img/placemarks/marker-bird.png',
                                    position = new google.maps.LatLng($item.data('lat'), $item.data('lng'));

                                markers.push(position);

                                version.default(pinFile).then(function (pin) {
                                    let marker = new google.maps.Marker({ // eslint-disable-line
                                        map,
                                        position,
                                        icon: {
                                            size: new google.maps.Size(600, 495),
                                            scaledSize: new google.maps.Size(36, 33),
                                            origin: new google.maps.Point(0, 0),
                                            anchor: new google.maps.Point(24, 43),
                                            url: pin,
                                        },

                                        // title: $item.data('title'),
                                        // cursor: 'pointer',
                                    });

                                    /*
                                    marker.addListener('click', () => {
                                        $item.find('a')[0].click();
                                    });
                                    */
                                });
                            });

                            // set default center to map if no one is present
                            // NOTE: if no center option is present the map will stay a white/blank box
                            if (!mapOptions.center) {
                                mapOptions.center = {
                                    lat: markers[0].lat(),
                                    lng: markers[0].lng(),
                                };
                            }

                            // init map
                            map = new google.maps.Map($container.find('.map__iframe')[0], mapOptions);

                            // add all markers to our map
                            markers.forEach(position => {
                                bounds.extend(position);
                            });

                            // restore zoom after fit bounds
                            if (mapOptions.zoom) {
                                let listener = google.maps.event.addListener(map, 'bounds_changed', function () {
                                    map.setZoom(mapOptions.zoom);
                                    google.maps.event.removeListener(listener);
                                });
                            }

                            // set the bounds based on the markers
                            map.fitBounds(bounds);
                        });
                    });
                });
            }
        };

        window.map = mapInit;
        mapInit();
    });
}, 'map');
