const $masonry = $('.masonry');

if ($masonry.length) {
    require(['imagesloaded', 'masonry-layout'], (imagesLoaded, Masonry) => {
        // loop over all our masonry blocks and init them
        $masonry.each(function (i, container) {
            imagesLoaded(container, () => {
                let masonry = new Masonry(container, {
                        columnWidth: '.masonry__sizer',
                        itemSelector: '.masonry__item',
                        percentPosition: true,
                    }),
                    $container = $(container),
                    $ajax = $container.data('ajax'),
                    $loadMore = $('.masonry__load-more'),
                    filter = $container.data('filter');

                // remake layout after something was filtered
                $container.on('filter.changed filter.filtered', function () {
                    masonry.layout();
                });

                if ($ajax) {
                    // get preload pages and change them to paged attribute
                    filter.paged = $container.data('preload-pages');

                    $loadMore.on('click', function () {
                        filter.paged++;

                        $.ajax({
                            url: '/wp-json/wpbp-ajax/posts/',
                            data: {
                                template: $container.data('template'),
                                template_items: $container.data('template-items'),
                                attrs: $container.data('attrs'),
                                related: $container.data('related'),
                                filter: filter,
                            },
                        }).then(function (data) {
                            if (!data.has_next_page) {
                                $loadMore.remove();
                            }

                            let $html = $(data.html);

                            $container.append($html);

                            imagesLoaded(container, () => {
                                $container.masonry('appended', $html);
                            });
                        });
                    });
                }
            });
        });
    });
}
